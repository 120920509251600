<template>
  <div></div>
</template>
<script>
import { PLATFORM } from '@/constants/enums'
import { sharedHelper } from '@/utils'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  created() {
    this.redirectToDownloadLink()
  },
  methods: {
    redirectToDownloadLink() {
      if (sharedHelper.getPlatform() == PLATFORM.DESKTOP) {
        this.$router.push({
          name: ROUTE_NAME.HOME
        })
      } else {
        if (sharedHelper.IsIos()) {
          window.location.replace('https://apps.apple.com/my/app/unbox-all-digital-needs/id1602781842')
        } else {
          window.location.replace('https://play.google.com/store/apps/details?id=com.keyboard.unbox.eshop')
        }
      }
    }
  }
}
</script>
